import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  quoteContainer: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  formControl: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
  },
  formControlName: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 600,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  skeletonForm: {
    margin: "auto",
    width: "80%",
  },
}));

const SuccessSkeleton = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div className={classes.skeletonForm}>
          <div className={classes.formControl}>
            <Skeleton variant="rect" width="80%" height={70} animation="wave" />
          </div>
          <div className={classes.formControl}>
            <Skeleton variant="rect" width="60%" height={56} animation="wave" />
          </div>
          <div className={classes.formControl}>
            <Skeleton variant="rect" width="30%" height={56} animation="wave" />
          </div>

          <div className={classes.formControl}>
            <Skeleton variant="rect" width="40%" height={56} animation="wave" />
          </div>

          <div className={classes.formControl}>
            <Skeleton variant="rect" width="80%" height={56} animation="wave" />
          </div>

          <div className={classes.formControl}>
            <Skeleton variant="rect" width="80%" height={32} animation="wave" />
          </div>

          <div className={classes.formControl}>
            <Skeleton variant="rect" width="35%" height={50} animation="wave" />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default SuccessSkeleton;
