import * as Yup from "yup";

export const cardSchema = Yup.object({
  number: Yup.number()
    .typeError("Number only")
    .required("Card Number is required"),
  expirationMonth: Yup.string("Select cc month").required("Month is required"),
  expirationYear: Yup.string("Select cc year").required("Year is required"),
  securityCode: Yup.number("Enter your CVC")
    .typeError("Number only")
    .min(3, "CVV should be of minimum 3 characters length")
    .required("CVV is required"),
  firstName: Yup.string("Enter your first name").required(
    "First Name is required"
  ),
  lastName: Yup.string("Enter your last name").required(
    "Last Name is required"
  ),
  address1: Yup.string("Enter address line 1").required(
    "Address line 1 is required"
  ),
  // address2: Yup.string("Enter address line 2").optional(),
  locality: Yup.string("Enter your city").required("City is required"),
  administrativeArea: Yup.string("Select your state or province").required(
    "State/Province is required"
  ),
  postalCode: Yup.string("Enter your Postal Code").required(
    "Postal Code is required"
  ),
  country: Yup.string("Select country").required("Country is required"),
  email: Yup.string("Enter your email")
    .email("Enter a vaild email")
    .required("Email is required"),
});
