import React, { useState, useEffect } from "react";
// import firebase from "../../config/fbConfig";
import { makeStyles } from "@material-ui/core/styles";
import "./quote.css";
import { useHistory } from "react-router-dom";

// form
import { useFormik } from "formik";
import { cardSchema } from "../../utils/validationSchema";

// compnents
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { popupAlert } from "../../utils/popupAlert";
import axios from "axios";
import Logo from "../branding/Logo";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
// import FormHelperText from "@material-ui/core/FormHelperText";
import LoadingSkeleton from "../Skeleton/LoadingSkeleton";

import securedImage from "../../assets/secure-payment.png";
import visa from "../../assets/credit-cards.jpg";
// import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    // maxWidth: "900px",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    width: "100%",
    marginTop: 20,
  },
}));

const Quote = (props) => {
  const history = useHistory();
  const classes = useStyles();
  // get current url
  const paramsQuoteId = props.match.params.quoteId;
  const [quote, setQuote] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [voidForm, setVoidForm] = useState(false);
  const localhostURL = "http://localhost:5001/hrs-quote/us-central1/app/api";
  const productionURL =
    "https://us-central1-hrs-quote.cloudfunctions.net/app/api";
  const now = new Date().getUTCFullYear();
  const years = Array(now - (now - 15))
    .fill("")
    .map((v, idx) => now + idx);
  useEffect(() => {
    loadQuote();
  }, []);

  const loadQuote = async () => {
    var config = {
      method: "get",
      url: `${productionURL}/get-quote/${paramsQuoteId}`,
    };
    try {
      let fetchQuote = await axios(config);

      if (
        fetchQuote.data.status === "OPEN" ||
        fetchQuote.data.status === "EXPIRED"
      ) {
        setQuote(fetchQuote.data);
      } else {
        setVoidForm(true);
      }
    } catch (error) {
      setVoidForm(true);
      popupAlert(
        "error",
        "Error",
        "Error in fetching quote information, please try again later"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      number: "",
      expirationMonth: "",
      expirationYear: "",
      securityCode: "",
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      locality: "",
      administrativeArea: "",
      postalCode: "",
      email: "",
      country: "",
      // phoneNumber: "",
    },
    validationSchema: cardSchema,
    onSubmit: async (values) => {
      setIsSubmit(true);

      let number = values.number.trim();
      let expirationYear = values.expirationYear;
      let expirationMonth = values.expirationMonth;
      let securityCode = values.securityCode.trim();
      let firstName = values.firstName.trim();
      let lastName = values.lastName.trim();
      let address1 = values.address1.trim();
      let address2 = values.address2 ? values.address2.trim() : "";
      let locality = values.locality;
      let administrativeArea = values.administrativeArea;
      let postalCode = values.postalCode;
      let email = values.email.trim().toLowerCase();
      let country = values.country;

      try {
        var data = {
          quoteId: quote.quoteId,
          firstName: firstName,
          lastName: lastName,
          address1: address1,
          address2: address2,
          locality: locality,
          administrativeArea: administrativeArea,
          postalCode: postalCode,
          country: country,
          email: email,
          number,
          expirationYear,
          securityCode,
          expirationMonth,
        };

        var config = {
          method: "post",
          url: `${productionURL}/process-payment/`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        let sendPostApi = await axios(config);

        // if card is declined show popup alert
        if (sendPostApi.data.status === "DECLINED") {
          popupAlert(
            "error",
            sendPostApi.data.status,
            `${sendPostApi.data.reason} - ${sendPostApi.data.message}`
          );
        }

        if (sendPostApi.data.status === 402) {
          popupAlert(
            "error",
            "Unable to process",
            `${sendPostApi.data.message}`
          );
        }

        if (sendPostApi.data.status === "INVALID_DATA") {
          popupAlert(
            "error",
            sendPostApi.data.status,
            `${sendPostApi.data.reason} - ${sendPostApi.data.message}`
          );
        }

        if (sendPostApi.data.status === "INVALID_REQUEST") {
          popupAlert(
            "error",
            sendPostApi.data.status,
            `${sendPostApi.data.reason} - ${sendPostApi.data.message}`
          );
        }

        if (sendPostApi.data.status === "AUTHORIZED") {
          popupAlert(
            "success",
            `THANK YOU`,
            `Invoice: ${quoteName} - Reference# ${sendPostApi.data.message} `
          );

          let historyData = {
            quoteName: quoteName,
            referenceNumber: sendPostApi.data.message,
            totalAmount: quote.totalAmount,
            currency: quote.currency,
          };

          history.push(`/success/${quote.quoteId}`, historyData);
        }
      } catch (error) {
        popupAlert("error", "Error", `${error}`);
      } finally {
        setIsSubmit(false);
      }
    },
  });

  const { currency, quoteName, totalAmount } = quote;

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <div
      className="quote-container"
      style={{ paddingBottom: 30, paddingTop: 30 }}
    >
      <Grid container spacing={1}>
        {!voidForm ? (
          <Grid item md={7} xs={12}>
            <div className="hrs-form__amount">
              <Logo />
              <div className="hrs-form__total">
                <ShoppingCartIcon
                  fontSize="medium"
                  style={{ color: "#2188c9", marginRight: 5 }}
                />
                <p>
                  <span style={{ fontSize: 24, fontWeight: "bold" }}>
                    ${totalAmount} {currency}
                  </span>
                </p>
              </div>
            </div>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            <div className="hrs-form__info">
              <h3 style={{ marginBottom: 0 }}>
                PAY WITH CREDIT CARD OR DEBIT CARD
              </h3>
              <p>We do not store any financial information.</p>
            </div>

            <div className="hrs-form__form" style={{ marginTop: 20 }}>
              <form
                onSubmit={formik.handleSubmit}
                className="hrs-form"
                form="myForm"
              >
                <div className="billing-info">
                  <TextField
                    style={{ width: "60%" }}
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    variant="outlined"
                    // style={{ width: "33%" }}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />

                  <TextField
                    style={{ width: "60%" }}
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    variant="outlined"
                    // style={{ width: "33%" }}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </div>
                <div className="billing-info__address">
                  <TextField
                    style={{ width: "100%" }}
                    id="address1"
                    name="address1"
                    label="Address Line 1"
                    value={formik.values.address1}
                    onChange={formik.handleChange}
                    variant="outlined"
                    // style={{ width: "33%" }}
                    error={
                      formik.touched.address1 && Boolean(formik.errors.address1)
                    }
                    helperText={
                      formik.touched.address1 && formik.errors.address1
                    }
                  />
                  <TextField
                    style={{ width: "100%" }}
                    id="address2"
                    name="address2"
                    label="Address Line 2"
                    value={formik.values.address2}
                    onChange={formik.handleChange}
                    variant="outlined"
                    // style={{ width: "33%" }}
                    error={
                      formik.touched.address2 && Boolean(formik.errors.address2)
                    }
                    helperText={
                      formik.touched.address2 && formik.errors.address2
                    }
                  />
                  <TextField
                    style={{ width: "100%" }}
                    id="locality"
                    name="locality"
                    label="City"
                    value={formik.values.locality}
                    onChange={formik.handleChange}
                    variant="outlined"
                    // style={{ width: "33%" }}
                    error={
                      formik.touched.locality && Boolean(formik.errors.locality)
                    }
                    helperText={
                      formik.touched.locality && formik.errors.locality
                    }
                  />

                  <TextField
                    variant="outlined"
                    name="administrativeArea"
                    style={{ width: "50%" }}
                    id="administrativeArea"
                    select
                    label="State or Province"
                    value={formik.values.administrativeArea}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.administrativeArea &&
                      Boolean(formik.errors.administrativeArea)
                    }
                    helperText={
                      formik.touched.administrativeArea &&
                      formik.errors.administrativeArea
                    }
                  >
                    <MenuItem key={""} value={""}></MenuItem>
                    <MenuItem value={"AB"}>{"Alberta"}</MenuItem>
                    <MenuItem value={"BC"}>{"British Columbia"}</MenuItem>
                    <MenuItem value={"MB"}>{"Manitoba"}</MenuItem>
                    <MenuItem value={"NB"}>{"New Brunswick"}</MenuItem>
                    <MenuItem value={"NL"}>
                      {"Newfoundland and Labrador"}
                    </MenuItem>
                    <MenuItem value={"NT"}>{"Northwest Territories"}</MenuItem>
                    <MenuItem value={"NS"}>{"Nova Scotia"}</MenuItem>
                    <MenuItem value={"NU"}>{"Nunavut"}</MenuItem>
                    <MenuItem value={"ON"}>{"Ontario"}</MenuItem>
                    <MenuItem value={"PE"}>{"Prince Edward island"}</MenuItem>
                    <MenuItem value={"QC"}>{"Quebec"}</MenuItem>
                    <MenuItem value={"SK"}>{"Saskatchewan"}</MenuItem>
                    <MenuItem value={"YT"}>{"Yukon"}</MenuItem>
                    {/* US */}
                    <MenuItem value={"AL"}>{"Alabama"}</MenuItem>
                    <MenuItem value={"AK"}>{"Alaska"}</MenuItem>
                    <MenuItem value={"AS"}>{"American Samoa"}</MenuItem>
                    <MenuItem value={"AZ"}>{"Arizona"}</MenuItem>
                    <MenuItem value={"AK"}>{"Arkansas"}</MenuItem>
                    <MenuItem value={"CA"}>{"California"}</MenuItem>
                    <MenuItem value={"CO"}>{"Colorado"}</MenuItem>
                    <MenuItem value={"CT"}>{"Connecticut"}</MenuItem>
                    <MenuItem value={"DE"}>{"Delaware"}</MenuItem>
                    <MenuItem value={"DC"}>{"Distric of Columbia"}</MenuItem>
                    <MenuItem value={"FM"}>
                      {"Federated States of Micronesia"}
                    </MenuItem>
                    <MenuItem value={"FL"}>{"Florida"}</MenuItem>
                    <MenuItem value={"GA"}>{"Georgia"}</MenuItem>
                    <MenuItem value={"GU"}>{"Guam"}</MenuItem>
                    <MenuItem value={"HI"}>{"Hawaii"}</MenuItem>
                    <MenuItem value={"ID"}>{"Idaho"}</MenuItem>
                    <MenuItem value={"IL"}>{"Illinois"}</MenuItem>
                    <MenuItem value={"IN"}>{"Indiana"}</MenuItem>
                    <MenuItem value={"IA"}>{"Iowa"}</MenuItem>
                    <MenuItem value={"KS"}>{"Kansas"}</MenuItem>
                    <MenuItem value={"KY"}>{"Kentucky"}</MenuItem>
                    <MenuItem value={"LA"}>{"Lousiana"}</MenuItem>
                    <MenuItem value={"ME"}>{"Maine"}</MenuItem>
                    <MenuItem value={"MH"}>{"Marshall Islands"}</MenuItem>
                    <MenuItem value={"MD"}>{"Maryland"}</MenuItem>
                    <MenuItem value={"MA"}>{"Massachusetts"}</MenuItem>
                    <MenuItem value={"MI"}>{"Michigan"}</MenuItem>
                    <MenuItem value={"MN"}>{"Minnesota"}</MenuItem>
                    <MenuItem value={"MS"}>{"Mississippi"}</MenuItem>
                    <MenuItem value={"MO"}>{"Missouri"}</MenuItem>
                    <MenuItem value={"MT"}>{"Montana"}</MenuItem>
                    <MenuItem value={"NE"}>{"Nebraska"}</MenuItem>
                    <MenuItem value={"NV"}>{"Nevada"}</MenuItem>
                    <MenuItem value={"NH"}>{"New Hampshire"}</MenuItem>
                    <MenuItem value={"NJ"}>{"New Jersey"}</MenuItem>
                    <MenuItem value={"NM"}>{"New Mexico"}</MenuItem>
                    <MenuItem value={"NY"}>{"New York"}</MenuItem>
                    <MenuItem value={"NC"}>{"North Carolina"}</MenuItem>
                    <MenuItem value={"ND"}>{"North Dakota"}</MenuItem>
                    <MenuItem value={"MP"}>
                      {"Northern Mariana Islands"}
                    </MenuItem>
                    <MenuItem value={"OH"}>{"Ohio"}</MenuItem>
                    <MenuItem value={"OK"}>{"Oklahoma"}</MenuItem>
                    <MenuItem value={"OR"}>{"Oregon"}</MenuItem>
                    <MenuItem value={"PW"}>{"Palau"}</MenuItem>
                    <MenuItem value={"PA"}>{"Pennsylvania"}</MenuItem>
                    <MenuItem value={"PR"}>{"Puerto Rico"}</MenuItem>
                    <MenuItem value={"RI"}>{"Rhode Island"}</MenuItem>
                    <MenuItem value={"SC"}>{"South Carolina"}</MenuItem>
                    <MenuItem value={"SD"}>{"South Dakota"}</MenuItem>
                    <MenuItem value={"TN"}>{"Tennessee"}</MenuItem>
                    <MenuItem value={"TX"}>{"Texas"}</MenuItem>
                    <MenuItem value={"UT"}>{"Utah"}</MenuItem>
                    <MenuItem value={"VT"}>{"Vermont"}</MenuItem>
                    <MenuItem value={"VI"}>{"Virgin Islands"}</MenuItem>
                    <MenuItem value={"VA"}>{"Virginia"}</MenuItem>
                    <MenuItem value={"WA"}>{"Washington"}</MenuItem>
                    <MenuItem value={"WV"}>{"West Virginia"}</MenuItem>
                    <MenuItem value={"WI"}>{"Wisconsin"}</MenuItem>
                    <MenuItem value={"WY"}>{"Wyoming"}</MenuItem>
                  </TextField>

                  <TextField
                    variant="outlined"
                    name="country"
                    style={{ width: "50%" }}
                    id="country"
                    select
                    label="Country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helperText={formik.touched.country && formik.errors.country}
                  >
                    <MenuItem value={"United States"}>United States</MenuItem>
                    <MenuItem value={"Canada"}>Canada</MenuItem>
                  </TextField>

                  <TextField
                    style={{ width: "50%" }}
                    id="postalCode"
                    name="postalCode"
                    label="Postal Code"
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                    variant="outlined"
                    // style={{ width: "33%" }}
                    error={
                      formik.touched.postalCode &&
                      Boolean(formik.errors.postalCode)
                    }
                    helperText={
                      formik.touched.postalCode && formik.errors.postalCode
                    }
                  />
                </div>

                <div className="email">
                  <TextField
                    style={{ width: "50%" }}
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    variant="outlined"
                    // style={{ width: "33%" }}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>

                <div className="month year cvc">
                  <TextField
                    fullWidth
                    id="number"
                    name="number"
                    label="Card Number"
                    // pattern="[0-9]*"
                    inputMode="numeric"
                    value={formik.values.number}
                    // autoComplete="cc-number"
                    variant="outlined"
                    // maxLength="19"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.number && Boolean(formik.errors.number)
                    }
                    helperText={formik.touched.number && formik.errors.number}
                  />

                  <TextField
                    variant="outlined"
                    name="expirationMonth"
                    style={{ width: "33%" }}
                    id="expirationMonth"
                    select
                    label="Expiration Month"
                    value={formik.values.expirationMonth}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.expirationMonth &&
                      Boolean(formik.errors.expirationMonth)
                    }
                    helperText={
                      formik.touched.expirationMonth &&
                      formik.errors.expirationMonth
                    }
                  >
                    <MenuItem key={""} value={""}></MenuItem>
                    <MenuItem value={"01"}>JAN</MenuItem>
                    <MenuItem value={"02"}>FEB</MenuItem>
                    <MenuItem value={"03"}>MAR</MenuItem>
                    <MenuItem value={"04"}>APR</MenuItem>
                    <MenuItem value={"05"}>MAY</MenuItem>
                    <MenuItem value={"06"}>JUN</MenuItem>
                    <MenuItem value={"07"}>JUL</MenuItem>
                    <MenuItem value={"08"}>AUG</MenuItem>
                    <MenuItem value={"09"}>SEP</MenuItem>
                    <MenuItem value={"10"}>OCT</MenuItem>
                    <MenuItem value={"11"}>NOV</MenuItem>
                    <MenuItem value={"12"}>DEC</MenuItem>
                  </TextField>

                  <TextField
                    variant="outlined"
                    name="expirationYear"
                    style={{ width: "33%" }}
                    id="expirationYear"
                    select
                    label="Expiration Year"
                    value={formik.values.expirationYear}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.expirationYear &&
                      Boolean(formik.errors.expirationYear)
                    }
                    helperText={
                      formik.touched.expirationYear &&
                      formik.errors.expirationYear
                    }
                  >
                    <MenuItem key={""} value={""}></MenuItem>
                    {years.map((year) => (
                      <MenuItem key={year} value={`${year.toString()}`}>
                        {year}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    style={{ width: "33%" }}
                    id="securityCode"
                    name="securityCode"
                    label="CVV"
                    value={formik.values.securityCode}
                    onChange={formik.handleChange}
                    variant="outlined"
                    // style={{ width: "33%" }}
                    error={
                      formik.touched.securityCode &&
                      Boolean(formik.errors.securityCode)
                    }
                    helperText={
                      formik.touched.securityCode && formik.errors.securityCode
                    }
                  />

                  <div className="hrs-form__creditCards">
                    <img src={visa} alt="credit cards" style={{ height: 40 }} />
                  </div>
                </div>
                {!isSubmit ? (
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    style={{ marginTop: 20, backgroundColor: "#2188c9" }}
                  >
                    SUBMIT PAYMENT
                  </Button>
                ) : (
                  <LinearProgress />
                )}
              </form>
            </div>
          </Grid>
        ) : (
          <Grid item md={7} xs={12}>
            <div className="hrs-form__amount">
              <Logo />
            </div>
            <h2 style={{ color: "red" }}>
              This quote has been paid or has expired. Please contact your sales
              rep.
            </h2>
          </Grid>
        )}

        {/* column 2  */}
        <Grid item md={5} xs={12}>
          <div
            className="hrs-form__securedImage"
            style={{ marginTop: voidForm ? 0 : "" }}
          >
            <img src={securedImage} alt="secured payment" />
            <h3 style={{ textAlign: "center" }}>NEW. FASTER. EASIER.</h3>
            <p style={{ textAlign: "center" }}>
              Welcome to the new Hawk Ridge Systems payment portal! Convenience
              you want, security you require.
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Quote;
