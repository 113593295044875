import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Logo from "../branding/Logo";
import axios from "axios";
import SuccessSkeleton from "../Skeleton/SuccessSkeleton";

const ThankYouPage = (props) => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isAlreadySent, setIsAlreadySent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  let paramsQuoteId = props.match.params.quoteId;
  let quoteName = props.location.state.quoteName
    ? props.location.state.quoteName
    : null;
  let totalAmount = props.location.state.totalAmount
    ? props.location.state.totalAmount
    : null;
  let referenceNumber = props.location.state.referenceNumber
    ? props.location.state.referenceNumber
    : null;
  let currency = props.location.state.currency
    ? props.location.state.currency
    : null;
  const localhostURL = "http://localhost:5001/hrs-quote/us-central1/app/api";
  const productionURL =
    "https://us-central1-hrs-quote.cloudfunctions.net/app/api";

  useEffect(() => {
    sendEmail();
  }, []);

  const sendEmail = async () => {
    var data = {
      quoteId: paramsQuoteId,
    };

    var config = {
      method: "post",
      url: `${productionURL}/email-invoice/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      let sendPostApi = await axios(config);

      if (sendPostApi.data.status === 200) {
        setIsEmailSent(true);
      }

      if (sendPostApi.data.status === 404) {
        setIsAlreadySent(true);
      }
    } catch (error) {
      console.log("error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = () => {
    window.print();
  };

  return (
    <div
      className="success-page"
      style={{ textAlign: "center", padding: 35, marginTop: 60 }}
    >
      <Logo />
      {isLoading ? (
        <>
          <SuccessSkeleton />
        </>
      ) : (
        <div className="title">
          <h1 style={{ color: "#2188c9", textTransform: "uppercase" }}>
            YOUR ORDER HAS BEEN RECEIVED
          </h1>
          <h2>Thank you for your payment</h2>
          <h3>Invoice: {quoteName}</h3>
          <h3>
            Total Paid: ${totalAmount} {currency}
          </h3>
          <h3>Payment Reference Number: {referenceNumber}</h3>
          <p>
            {isEmailSent &&
              "An email has been sent as well. Please feel free to print this page for your reference."}
            {isAlreadySent &&
              "An email has already been sent. Please feel free to print this page for your reference."}
          </p>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={handleClick}
            style={{ marginTop: 20, backgroundColor: "#2188c9", width: 300 }}
          >
            Print
          </Button>
        </div>
      )}
    </div>
  );
};

export default ThankYouPage;
