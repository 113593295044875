import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// components
import { Container, Paper } from "@material-ui/core";
import Quote from "./components/quote/Quote";
import ErrorPage from "./components/errorPage/ErrorPage";
import ThankYouPage from "./components/thankYouPage/ThankYouPage";

const App = () => {
  return (
    <BrowserRouter className="App">
      <Container maxWidth="lg" style={{ paddingTop: 60, paddingBottom: 60 }}>
        <Paper elevation={3} style={{ paddingLeft: 24, paddingRight: 24 }}>
          {/* <Navbar /> */}
          <Switch>
            <Route exact path="/" component={ErrorPage} />
            <Route exact path="/success/:quoteId" component={ThankYouPage} />
            <Route path="/:quoteId" component={Quote} />
          </Switch>
        </Paper>
      </Container>
    </BrowserRouter>
  );
};

export default App;
