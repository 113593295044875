import React from "react";
import Logo from "../branding/Logo";

const ErrorPage = () => {
  return (
    <div
      className="success-page"
      style={{ textAlign: "center", padding: 35, marginTop: 60 }}
    >
      <Logo />
      <div className="title">
        <h1 style={{ color: "#2188c9", textTransform: "uppercase" }}>Error</h1>
        <h2>Unable to access payment form. Please contact your sales rep</h2>
      </div>
    </div>
  );
};

export default ErrorPage;
