import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  quoteContainer: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  formControl: {
    marginTop: 20,
  },
  formControlName: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 600,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  skeletonForm: {
    width: "80%",
  },
}));

const LoadingSkeleton = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes.quoteContainer}>
      <Grid item xs={12} md={7}>
        <Skeleton variant="rect" width="100%" height={70} animation="wave" />

        <Divider style={{ marginTop: 30, marginBottom: 30 }} />
        <div className={classes.skeletonForm}>
          <Skeleton variant="rect" width="70%" height={40} animation="wave" />
          <div className={classes.formControl}>
            <Skeleton variant="rect" width="50%" height={32} animation="wave" />
          </div>
          <div className={classes.formControl}>
            <Skeleton variant="rect" width="50%" height={56} animation="wave" />
          </div>
          <div className={classes.formControl}>
            <Skeleton variant="rect" width="50%" height={56} animation="wave" />
          </div>

          <div className={classes.formControl}>
            <Skeleton
              variant="rect"
              width="100%"
              height={60}
              animation="wave"
            />
          </div>
          <div className={classes.formControl}>
            <Skeleton
              variant="rect"
              width="100%"
              height={60}
              animation="wave"
            />
          </div>
          <div className={classes.formControl}>
            <Skeleton
              variant="rect"
              width="100%"
              height={60}
              animation="wave"
            />
          </div>
          <div className={classes.formControl}>
            <Skeleton
              variant="rect"
              width="100%"
              height={60}
              animation="wave"
            />
          </div>
          <div className={classes.formControl}>
            <Skeleton variant="rect" width="50%" height={56} animation="wave" />
          </div>
          <div className={classes.formControl}>
            <Skeleton variant="rect" width="50%" height={56} animation="wave" />
          </div>
          <div className={classes.formControl}>
            <Skeleton
              variant="rect"
              width="100%"
              height={60}
              animation="wave"
            />
          </div>
          <div className={classes.formControl}>
            <Skeleton
              variant="rect"
              width="100%"
              height={60}
              animation="wave"
            />
          </div>
          <div className={classes.formControl}>
            <Skeleton
              variant="rect"
              width="100%"
              height={60}
              animation="wave"
            />
          </div>
        </div>
      </Grid>

      <Grid item xs={12} md={5}>
        <div className="hrs-form__securedImage">
          <Skeleton variant="rect" width="100%" height={225} animation="wave" />
        </div>
      </Grid>
    </Grid>
  );
};

export default LoadingSkeleton;
